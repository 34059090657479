import * as React from 'react';
import { Button, Container, CssBaseline, TextField, Link, Grid, Box } from '@mui/material';


const ForgotPassword = () => {

    React.useEffect(() => {
        document.title = "Jaman Enterprise | Forgot";
    }, []);


    return (
        <Container component="main" maxWidth="xs" style={{ marginTop: "5rem", paddingTop: "2rem", height: "60vh" }}>
            <CssBaseline />
            <Box
                style={{ marginTop: "6rem" }}

            >
                <Box component="form" noValidate sx={{ mt: 3 }} >
                    <Grid container >
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Registered Email Id"
                                name="email"
                                autoComplete="email"

                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        style={{ color: "white" }}
                    >
                        send reset email
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link href="/login" variant="body2">
                                Rememberd password? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                    <br></br>
                </Box>
            </Box>
        </Container>
    );
}


export default ForgotPassword 