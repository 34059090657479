import React from 'react';
import Carousel from "react-material-ui-carousel";
import { Grid } from '@mui/material';

const BannerCarousel = (props: any) => {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Carousel animation="slide">
            <div>
              <img
                src="/static/banner3.jpeg" alt="banner 1"
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
            <div>
              <img
                src="/static/banner1.jpeg" alt="banner 2"
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
            <div>
              <img
                src="/static/banner4.jpeg" alt="banner 3"
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
            <div>
              <img
                src="/static/banner2.jpeg" alt="banner 4"
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
          </Carousel>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BannerCarousel;
