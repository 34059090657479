import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Link,
  Grid,
  Container,
  Typography,
  Box,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../store/authSlice";
import { userLogin } from "app/services/customer/customerAPI";
import { setToken } from "app/services/UtilServices";
import GoogleAuth from "../components/Auth/GoogleAuth";

const Login = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    document.title = "Jaman Enterprise | Login";
  }, []);

  return (
    <Container component="main" maxWidth="xs" style={{ height: "70vh" }}>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email("Must be a valid email").required("Required"),
          password: Yup.string().required("Required"),
        })}
        onSubmit={async (values) => {
          try {
            const result = await userLogin(values.email, values.password);
            if (result.success) {
              toast.success("Login Successfully!");
              localStorage.clear();
              setToken(result.data.token);
              dispatch(login());
              history.push("/");
              window.location.reload();
            } else {
              toast.error(result.message)

            }
          } catch (error) {
            toast.error(error.message);
            toast.error("Check login details")
            console.error(error);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form style={{ marginTop: "12rem" }}>
            <Box
              sx={{
                marginTop: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle2" gutterBottom>
                Login to continue <span style={{ color: "#ff9800" }}>Jaman Enterprise</span>
              </Typography>

              <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="password"
                    label="Password"
                    type={passwordShown ? "text" : "password"}
                    id="password"
                    autoComplete="new-password"
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    InputProps={{
                      endAdornment: (
                        <Button onClick={togglePasswordVisibility}>
                          {passwordShown ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </Button>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Button
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{ color: "white" }}
                fullWidth
              >
                Login
              </Button>

              <Grid container>
                <Grid item xs m={1}>
                  <Link style={{ color: "black" }} href="/reset" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item m={1}>
                  <Link style={{ color: "black" }} href="/register" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>

              </Grid>
              <GoogleAuth />



            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Login;
