import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, CardMedia, Grid, TextField, MenuItem } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import './productStyle.css';
import { addItem, updateQuantity, removeItem, calculateTotalAmount } from '../../store/cartSlice';
import { processUrl } from 'common/Service';
import { useHistory } from 'react-router-dom';




const Product = (props: any) => {

    const history = useHistory();
    const redirectToProductDetails = (product: any) => {
        // Save the selected product to local storage
        localStorage.setItem('selectedProduct', JSON.stringify(product));
        history.push({
            pathname: `/product-details/${product._id}`,
            state: { product }
        });
    };

    const dispatch = useDispatch();
    const [filterCriteria, setFilterCriteria] = React.useState({ brandName: '', manufacturer: '', mrp: '' });
    const [sortOrder, setSortOrder] = React.useState(''); // Ascending or Descending
    const { products } = props;

    const cartItems = useSelector((state: any) => state.cart.items);
    const handleAddToCart = (product: any) => {
        const existingItem = cartItems.find((item: any) => item.product._id === product._id);
        console.log("log", existingItem)
        if (existingItem) {
            dispatch(updateQuantity({ id: product._id, quantity: existingItem.quantity + 1 }));
            dispatch(calculateTotalAmount());
        } else {
            console.log("log add", product)
            dispatch(addItem({ product: product }));
            dispatch(calculateTotalAmount());
        }
        toast.success(`${product.title} added to cart`);
    };



    const handleRemoveFromCart = (product: any) => {
        const existingItem = cartItems.find((item: any) => item.product._id === product._id);
        if (existingItem && existingItem.quantity > 1) {
            dispatch(updateQuantity({ _id: product._id, quantity: existingItem.quantity - 1 }));
            dispatch(calculateTotalAmount());

        } else {
            //@ts-ignore
            dispatch(removeItem({ _id: product._id }));
            dispatch(calculateTotalAmount());
        }
        toast.warn(`${product.title} removed from cart`);
    };

    const uniqueBrandNames = Array.from(new Set(products.map((product: any) => product.brand)));
    const uniqueManufacturers = Array.from(new Set(products.map((product: any) => product.manufacturer)));
    const filteredProducts = products.filter((product: any) => {
        const { brandName, manufacturer } = filterCriteria;
        return (
            (!brandName || product.brand.toLowerCase() === brandName.toLowerCase()) &&
            (!manufacturer || product.manufacturer.toLowerCase() === manufacturer.toLowerCase())
        );
    });

    const sortedProducts = filteredProducts.slice().sort((a: any, b: any) => {
        if (sortOrder === 'highToLow') {
            return b.mrp - a.mrp;
        } else if (sortOrder === 'lowToHigh') {
            return a.mrp - b.mrp;
        }
        return 0;
    });


    function calculatePriceDifferencePercentage(mrp: number, sellingPrice: number) {
        const priceDifference = mrp - sellingPrice;
        // Calculate the percentage difference
        const percentageDifference = (priceDifference / mrp) * 100;
        return percentageDifference.toFixed(0); // Return with two decimal places
    }


    return (
        <React.Fragment >
            <div style={{ margin: "5px", padding: "5px" }}>

                <Grid container spacing={2} >
                    <Grid item xs={12} md={2}>
                        <TextField
                            fullWidth
                            select
                            label="Filter By Brand"
                            variant="outlined"
                            value={filterCriteria.brandName}
                            onChange={(e) => setFilterCriteria({ ...filterCriteria, brandName: e.target.value })}
                        >
                            <MenuItem value="">All</MenuItem>
                            {uniqueBrandNames.map((brandName: any, index: any) => (
                                <MenuItem key={index} value={brandName}>{brandName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            select
                            label="Filter by Manufacturer"
                            variant="outlined"
                            value={filterCriteria.manufacturer}
                            onChange={(e) => setFilterCriteria({ ...filterCriteria, manufacturer: e.target.value })}
                        >
                            <MenuItem value="">All</MenuItem>
                            {uniqueManufacturers.map((manufacturer: any, index: any) => (
                                <MenuItem key={index} value={manufacturer}>{manufacturer}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={5}>

                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            fullWidth
                            select
                            label="Sort by MRP"
                            variant="outlined"
                            value={sortOrder}
                            onChange={(e) => setSortOrder(e.target.value)}
                        >
                            <MenuItem value="">None</MenuItem>
                            <MenuItem value="highToLow">High to Low</MenuItem>
                            <MenuItem value="lowToHigh">Low to High</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container >
                    {sortedProducts.map((product: any) => (
                        <Grid item xs={12} sm={6} md={3} key={product.id} spacing={2} >

                            <div className="pcards-container" key={product._id}
                                onClick={() => redirectToProductDetails(product)}

                            >

                                <div className="pcard" >
                                    <div className="ribbon-wrapper">
                                        <div className="ribbon-pop">{calculatePriceDifferencePercentage(product.mrp, product.sellingPrice)}% OFF</div>
                                    </div>
                                    <div className="pcard-media">
                                        <div >
                                            <CardMedia
                                                sx={{ height: 210 }}
                                                image={processUrl(product.imageUrl)}
                                                title={product.title}
                                            />
                                        </div>

                                    </div>
                                    <div className="pcard-description">
                                        <div className="about-place">
                                            <div className="place">
                                                <div>
                                                    <h4 className="place-name">
                                                        {product.title}
                                                    </h4>
                                                </div>
                                                <p className="place-speciality" style={{ paddingBottom: "8px" }}>{product.title}&nbsp;:{product.brand}</p>
                                            </div>
                                            <div className="place-review">
                                                <div>
                                                    <span style={{ fontWeight: "bold" }}>
                                                        <del style={{ fontWeight: "lighter" }}>
                                                            ₹{product.mrp}
                                                        </del>
                                                        ₹{product.sellingPrice}
                                                    </span>
                                                </div>
                                                <div className="quantity-control">
                                                    {cartItems.find((item: any) => item.product.id === product.id)?.quantity > 0 && (
                                                        <IconButton
                                                            size='small'
                                                            onClick={() => handleRemoveFromCart(product)}
                                                            style={{ borderRadius: "5px", color: "white", backgroundColor: "#0ea04e", }}
                                                        >
                                                            <RemoveIcon fontSize='small' />
                                                        </IconButton>
                                                    )}
                                                    {cartItems.find((item: any) => item.product.id === product.id)?.quantity > 0 && (
                                                        <div className="quantity">
                                                            {cartItems.find((item: any) => item.product.id === product.id)?.quantity || 0}
                                                        </div>
                                                    )}
                                                    <IconButton
                                                        size='small'
                                                        onClick={() => handleAddToCart(product)}
                                                        style={{ borderRadius: "5px", color: "white", backgroundColor: "#0ea04e" }}
                                                    >
                                                        <AddIcon fontSize='small' />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>

        </React.Fragment >
    );
};

export default Product;
