import React, { useState, useEffect } from "react";
import { Grid, Button, Card } from "@mui/material";
import { RAZOR_PAY_KEY } from "../common/constant";
import { toast } from "react-toastify";
import { confirmOrder } from "app/services/order/orderAPI";
import { useHistory } from "react-router-dom";
import { findUserId } from "app/services/UtilServices";
import Spinner from "components/Miscellaneous/Spinner";


interface IOrder {
    order: {
        paymentObj?: object
        rzpPayId: string
        id: string
        rzpOrderId: string
        orderNo: string
        orderTime?: Date
    },
    paymentObj: Object,
    setPaymentObj: React.Dispatch<React.SetStateAction<Object>>;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;

}


const MakePayment: React.FC<IOrder> = ({ order, paymentObj, setPaymentObj, setActiveStep }) => {

    let history = useHistory();
    const [loading, setLoading] = React.useState(false);

    const [user, setUser] = useState({
        name: "",
        email: "",
        mobile: "",
        gender: "",
    });

    console.log("order modal", order)
    const openPayModal = () => {
        const _window = window as any;
        const rzp = new _window.Razorpay({
            key: RAZOR_PAY_KEY,
            currency: "INR",
            order_id: order.rzpOrderId,
            name: "Jaman Enterprise",
            image: "/static/onlyLogo.png",
            description: "Jaman Enterprise-Hariharpara",
            handler: function (response: any) {
                if (response.razorpay_payment_id) {
                    setLoading(true)
                    const data = {
                        orderId: order.id, //order id
                        rzpPaymentId: response.razorpay_payment_id, //payment id
                        rzpOrderId: response.razorpay_order_id,
                        orderNo: order.orderNo,
                        id: order.id,
                        userId: findUserId()
                    };
                    handleConfirmOrder(data);
                }
            },
            theme: {
                hide_topbar: false,
                color: "#F58320",
            },
            // ... rest of the configuration ...
        });
        rzp.open();
        rzp.on("Payment failed", (response: any) => {
            toast.info(response)
            console.log("response", response);
        });
    };

    useEffect(() => {
        document.title = "Payment | Jaman Enterprise";
        window.scrollTo(0, 0);
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const handleConfirmOrder = async (data: any) => {
        try {

            const result = await confirmOrder(data);
            if (result.data) {
                console.log("confirm order", result.data);
                setLoading(false)
                toast.success(
                    `Your payment was successful`
                );
                setPaymentObj(result.data)
                setActiveStep(5)
            }
        } catch (error) {
            setLoading(false)
            toast.error(error)
            console.log("confirm error", error);
            console.log(error);
        }
    };

    return (
        <React.Fragment>
            <Grid container>
                {loading ?
                    <Spinner />
                    :
                    <Grid item xs={12} sm={12} md={12}>
                        <Card>
                            <Button
                                variant="contained"
                                style={{
                                    color: "white",
                                    width: "275px",
                                    borderRadius: "10px",
                                    margin: "1rem"
                                }}
                                onClick={openPayModal}
                            >
                                Proceed to payment
                            </Button>
                        </Card>
                    </Grid>}
            </Grid>
        </React.Fragment>
    );
};

export default MakePayment;
