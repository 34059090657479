import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Divider, Button, Card } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import RandomRatingReview from './RatingReview';
import { processUrl } from 'common/Service';

const ProductDetails = () => {
    const [product, setProduct] = useState(null);

    useEffect(() => {
        // Retrieve the product from local storage
        const product = JSON.parse(localStorage.getItem('selectedProduct'));
        setProduct(product);
    }, []);

    const randomRating = () => {
        const rating = Math.floor(Math.random() * 3) + 3;
        return rating;
    };

    const breakParaIntoList = (para: string) => {
        const items = para.split(',').map((item, index) => {
            // Trim white spaces around the item
            const trimmedItem = item.trim();
            // Ensure there is content before creating an <li> element
            if (trimmedItem) {
                return <li key={index}>{trimmedItem}</li>;
            }
            return null;
        });

        return <ul>{items}</ul>;
    };

    if (!product) {
        return (
            <Container maxWidth="lg" style={{ marginTop: "14rem" }}>
                <Typography variant="h2">Product Details Not Found</Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" style={{ marginTop: "14rem" }}>
            <div>
                <h2>Product Details:</h2>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} style={{ overflow: 'auto', paddingTop: "2rem" }}>
                    <div style={{ marginRight: "5px", paddingTop: "35px" }}>
                        <img
                            src={processUrl(product.imageUrl)} // Displaying product image
                            alt={product.title}
                            style={{ width: "90%" }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ paddingLeft: "1rem" }}>
                    <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
                        <Typography variant="h5">
                            {product.title}
                        </Typography>
                        <Typography variant='h6' color='primary' style={{ display: "flex", alignItems: "center" }}>
                            {randomRating()} <StarIcon color='primary' fontSize='small' />
                            (381 Ratings & 1 Review)
                        </Typography>
                        <Typography variant='h4' style={{ fontWeight: "600" }}>
                            ₹{product?.sellingPrice}
                        </Typography>
                        <Typography variant="body2">
                            (Incl. all Taxes)
                        </Typography>
                        <br />
                        <Divider />
                        <br />

                        <Typography variant="body2" component="div">
                            <del>MRP: ₹{product?.mrp}</del>
                            <span>(Save ₹{product.mrp - product.sellingPrice}, {((product.mrp - product.sellingPrice) / product.mrp * 100).toFixed(2)}% off)</span>
                        </Typography>
                        <div style={{ marginTop: "1rem" }}>
                            <p>Type of Ignition

                            </p>
                            <Button variant='outlined' color='primary' style={{ marginRight: "1rem" }}>
                                {product.ignitionType}
                            </Button>
                            <p>No. of Burners

                            </p>
                            <Button variant='outlined' color='primary'>
                                {product.noOfBurner}
                            </Button>
                        </div>
                        <div style={{ marginTop: "1rem", color: "white" }}>
                            <Button variant='contained' color="primary" size="large" >
                                Buy Now
                            </Button>
                            <Button variant='contained' color='secondary' size="large" style={{ color: "white" }}>
                                Add to cart
                            </Button>
                        </div>
                        <Card style={{ margin: "1rem 0", padding: "1rem" }}>
                            <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
                                Key Features:
                            </Typography>
                            {breakParaIntoList(product.description)}
                        </Card>
                        <div style={{ marginBottom: "1rem" }}>


                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    Specification:
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {product.description}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    Ratings & Review:
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RandomRatingReview randomRating={randomRating} />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Container>

    );
};

export default ProductDetails;
