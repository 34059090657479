import * as React from "react";
import { Button, Card, CardActions, CardContent, CssBaseline, Grid, Typography, Paper, Container, CardMedia } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const blogs = [
    {
        imgurl: "/blog/blog1.png",
        title: "Quick Delivery",
        description: "Lightning-fast service, delivering your favorite meals promptly to your door.",
        buttonText: "see more",
        buttonVariant: "outlined",
    },
    {
        imgurl: "/blog/blog2.png",
        title: "A Good Auto Responder",
        description: "Fresh, top-quality ingredients prepared with meticulous hygiene for your well-being.",
        buttonText: "view ofer",
        buttonVariant: "outlined",
    },
    {
        imgurl: "/blog/blog3.png",
        title: "Home Delivery",
        description: "Enjoy unbeatable discounts on local shop delights, making dining delightful and affordable.",
        buttonText: "read more",
        buttonVariant: "outlined",
    },
];

const Blog = () => {
    return (
        <React.Fragment>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4}>
                    <img
                        src="/static/blog1.jpg" alt="banner 2"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <img
                        style={{ width: '100%', height: 'auto' }}
                        src='/static/blog2.jpg'
                        title="FREE SHIPPING"
                    />
                </Grid>
                <Grid item xs={4}>
                    <img
                        style={{ width: '100%', height: 'auto' }}
                        src='/static/blog3.jpg'
                        title="FREE SHIPPING"
                    />
                </Grid>

            </Grid>
        </React.Fragment>
    );
};

export default Blog;
