import React from 'react';
import { Container, Grid } from '@mui/material';
import './aboutStyle.css'
import { FaFacebookMessenger } from 'react-icons/fa';
import { HiChatBubbleBottomCenterText } from 'react-icons/hi2';


const AboutUsPage = () => {


    return (
        <>
            <Container maxWidth="lg" component="main" >
                <Grid container style={{ marginTop: "10rem", textAlign: "center" }}>

                    <Grid item md={12} sm={12} xs={12}>
                        <div className="contact-address-area">
                            <div className="container">
                                <div className="sec-title-style1">
                                    <div className="title">Contact Us</div>
                                    <div className="text"><div className="decor-left"><span></span></div><p>Quick Contact</p><div className="decor-right"><span></span></div></div>
                                    <div className="bottom-text">
                                        <p style={{
                                            wordBreak: "normal",
                                            whiteSpace: "normal"
                                        }}>At Jaman Enterprise, we are passionate about delivering the best local restaurant foods right to your doorstep. We value your experience and are committed to providing top-notch customer service.</p>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="contact-info-area">
                            <div className="container">
                                <div className="contact-form">
                                    <Grid container >
                                        <Grid item xs={12} md={6}>

                                        </Grid>

                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <br></br>
                    <Grid item md={4} sm={12} xs={12} className="single-contact-address-box " style={{ backgroundColor: "black", textDecoration: "underline", height: "30rem" }}>
                        <div>

                            <h3>Write to Us</h3>
                            <a href="mailto:contact@Jaman Enterprise.com" target="_blank" rel="noopener noreferrer">sales@jamanenterprise.com</a>
                        </div>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} className="single-contact-address-box main-branch">
                        <h3>Office Hours</h3>
                        <div className="inner ">
                            <ul>
                                <li>
                                    <div className="title">
                                        <h4>Address:</h4>
                                    </div>
                                    <div className="text">
                                        <p>Near old Cinema house, Hariharpara, Murshidabad,<br />WB, 742166 4</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="title">
                                        <h4>Ph & Fax:</h4>
                                    </div>
                                    <div className="text">
                                        <p>+123 456 789 <br /> sales@jamanenterprise.com</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="title">
                                        <h4>Office Hrs:</h4>
                                    </div>
                                    <div className="text">
                                        <p>Mon-Sat: 9:30am - 9:30pm<br /> Friday: 3pm-9:30pm</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} className="single-contact-address-box">

                        <h3>Chat with Us</h3>
                        <a href='https://www.facebook.com/jamanenterprise.in' target="_blank" rel="noopener noreferrer" >
                            <FaFacebookMessenger color='FFA500' size="50" />
                        </a>

                    </Grid>

                </Grid >
                <Grid container >
                    <Grid item xs={12} sm={12} md={12}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d910.9107230086636!2d88.41810502936094!3d24.04365499999999!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f961cd733f83d7%3A0x4b56f31a1446a835!2sJaman%20Enterprise!5e0!3m2!1sen!2sin!4v1707557224697!5m2!1sen!2sin" width="100%" height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Grid>
                </Grid>
            </Container >
        </>
    );
};

export default AboutUsPage;
