import * as React from 'react';
import { Button, CssBaseline, TextField, FormControlLabel, Link, Grid, Box, Typography, Radio, RadioGroup, FormControl } from '@mui/material';
import Container from '@mui/material/Container';
import { toast } from "react-toastify";
import { Formik, Form, validateYupSchema, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom"
import { setToken } from 'app/services/UtilServices';
import { userRegister } from 'app/services/customer/customerAPI';
import Spinner from 'components/Miscellaneous/Spinner';

const RegisterPage = () => {
    let history = useHistory();
    const [loading, setLoading] = React.useState(false)
    const [value, setValue] = React.useState('male');
    const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };
    const [errors, setErrors] = React.useState({});



    const validationSchema = Yup.object().shape({
        firstName: Yup.string().max(25).required('First name is required'),
        lastName: Yup.string().max(25).required('Last name is required'),
        gender: Yup.string().max(25).required('Gender is required'),
        mobile: Yup.string()
            .required('Mobile is required')
            .matches(/^[0-9]+$/, 'Must be only digits')
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits'),
        email: Yup.string().email('Must be a valid email').max(50).required('Email is required'),
        password: Yup.string().min(6, 'Password must be at least 6 characters').max(50).required('Password is required'),
    });

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                style={{ marginTop: "10rem" }}
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h5" gutterBottom component="div">
                    Welcome to <span style={{ color: "#FF9800" }} >Jaman Enterprise</span>
                </Typography>
                <Box sx={{ mt: 3 }} style={{ marginTop: "3rem" }}>
                    {loading ? <Spinner /> :

                        <Formik
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                email: '',
                                mobile: '',
                                password: '',
                                gender: 'male'
                            }}
                            validationSchema={validationSchema}
                            onSubmit={async (values: any) => {
                                setLoading(true)

                                try {
                                    const data = {
                                        "firstName": values.firstName,
                                        "lastName": values.lastName,
                                        "login_type": "email",
                                        "email": values.email,
                                        "phone": values.mobile.toString(),
                                        "fullName": values.name,
                                        "password": values.password,
                                        "gender": value
                                    }
                                    const result = await userRegister(data)
                                    if (result.data && result.data.token != null) {
                                        toast.success("Account created successfully");
                                        setLoading(false)
                                        setToken(result.data.token)
                                        history.push('/')
                                        window.location.reload()
                                    }

                                } catch (error) {
                                    toast.error(error.message);
                                    console.log(error)
                                }
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                                isValid,
                                dirty
                            }) => (
                                <div>
                                    <Form onSubmit={handleSubmit}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} >
                                                <TextField
                                                    error={Boolean(touched.firstName && errors.firstName)}
                                                    autoComplete="firstName"
                                                    name="firstName"
                                                    required
                                                    fullWidth
                                                    id="firstName"
                                                    label="First Name"
                                                    autoFocus
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                <ErrorMessage name="firstName" component="p" style={{ color: 'red' }} />

                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    error={Boolean(touched.lastName && errors.lastName)}
                                                    autoComplete="lastName"
                                                    name="lastName"
                                                    required
                                                    fullWidth
                                                    id="lastName"
                                                    label="Last Name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                <ErrorMessage name="lastName" component="p" style={{ color: 'red' }} />

                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    error={Boolean(touched.email && errors.email)}
                                                    required
                                                    fullWidth
                                                    id="email"
                                                    label="Email Address"
                                                    name="email"
                                                    autoComplete="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="email"
                                                    value={values.email}
                                                />
                                                <ErrorMessage name="email" component="p" style={{ color: 'red' }} />

                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    error={Boolean(touched.mobile && errors.mobile)}
                                                    required
                                                    fullWidth
                                                    id="mobile"
                                                    label="Mobile Number"
                                                    name="mobile"
                                                    autoComplete="mobile"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="number"
                                                    value={values.mobile}
                                                    inputProps={{ maxlength: 10, inputMode: 'numeric' }}

                                                />
                                                <ErrorMessage name="mobile" component="p" style={{ color: 'red' }} />

                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup aria-label="gender" name="gender" value={value} onChange={handleChangeRadio} style={{ flexDirection: "row" }}>
                                                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                                                        <FormControlLabel value="female" control={<Radio />} label="Female" />

                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    error={Boolean(touched.password && errors.password)}
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    id="password"
                                                    autoComplete="new-password"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.password}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" gutterBottom>
                                                    By clicking on enter, you accept the
                                                    <Link rel="stylesheet" href="/terms-condition" >&nbsp; terms and conditions</Link>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Button
                                            disabled={!isValid || !dirty || isSubmitting}
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            style={{ color: "white" }}
                                        >
                                            Sign Up
                                        </Button>
                                    </Form>
                                </div>
                            )}
                        </Formik>
                    }

                </Box>
            </Box>

            <br></br>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Link href="/login" variant="body2">
                        Existing user? Sign in
                    </Link>
                </Grid>
            </Grid>  <br></br>

        </Container>
    );
}


export default RegisterPage