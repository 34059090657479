import React, { useState, useEffect } from 'react';
import StarIcon from '@mui/icons-material/Star';

const RandomRatingReview = (props: any) => {
    const { randomRating } = props
    const [randomReviews, setRandomReviews] = useState(0);
    const [randomComments, setRandomComments] = useState<any>([]);

    useEffect(() => {
        const generateRandomName = () => {
            const firstNames = ["Aarav", "Aarya", "Milon", "Advait", "Ananya", "Aryan", "Asmaul", "Ishaan", "Kabir", "Riya", "Saanvi", "ALi", "Arnav", "Avni", "Ishita", "Jay", "Kiara", "Krishna", "Myra", "Pranav"];
            const lastNames = ["Agarwal", "Bansal", "Chopra", "Deshmukh", "Gupta", "Biswas", "Kulkarni", "Malhotra", "SK", "Rao", "Shah", "Sharma", "Singh", "Tiwari", "Trivedi", "Verma", "Mondal", "Islam"];
            const randomFirstName = firstNames[Math.floor(Math.random() * firstNames.length)];
            const randomLastName = lastNames[Math.floor(Math.random() * lastNames.length)];
            return `${randomFirstName} ${randomLastName}`;
        };

        const fetchRatingAndReviews = () => {
            // Generate random number of reviews between 0 and 100
            const reviews = Math.floor(Math.random() * 101);
            setRandomReviews(reviews);
        };

        const fetchComments = () => {
            setTimeout(() => {
                const comments = [
                    "Very nice product, highly recommended!",
                    "Average quality, but good value for money.",
                    "Not satisfied with the product, expected better.",
                    "Fantastic service and fast delivery.",
                    "Could be better, needs improvement.",
                    "Got On time delivery",
                    "Cheaper than offline store",
                    "ON time delivery, love it",
                    "we should support local, go for it"
                ];
                // Shuffle the comments array
                const shuffledComments = comments.sort(() => Math.random() - 0.5);
                // Select a random subset of comments (e.g., 3 comments)
                const randomSubset = shuffledComments.slice(0, 3);
                const randomizedComments = randomSubset.map(comment => {
                    const user = generateRandomName();
                    return { user, comment };
                });
                setRandomComments(randomizedComments);
            }, 1000);
        };

        fetchRatingAndReviews();
        fetchComments();
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <h3>Overall Rating : {randomRating()}/5</h3>
                    <div className="rating-stars">
                        {[...Array(5)].map((_, index) => (
                            <StarIcon key={index} style={{ color: index < randomRating() ? '#ffc107' : '#e4e5e9' }} />
                        ))}
                    </div>
                    <p>Based on {randomReviews !== null ? randomReviews : 'Loading...'} Rating</p>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-6">
                    <h4>Customer Reviews:</h4>
                    <ul>
                        {randomComments.map((comment: any, index: number) => (
                            <li key={index}>
                                <strong>{comment.user}</strong> <br />
                                <strong>Rating:</strong> {[...Array(randomRating())].map((_, i) => <StarIcon key={i} style={{ color: '#ffc107' }} />)} <br />
                                {comment.comment}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default RandomRatingReview;
