import React from "react";

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';


const Copyright = () => {
  return (
    <Typography variant="body2" color="main" align="center">
      {'Copyright © '}
      <Link color="primary" href="http://www.Jaman Enterprise.com" target="_blank">
        Jaman Enterprise
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default Copyright