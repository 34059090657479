import React, { useEffect, useState } from "react";
import HeroSection from "../components/HeroSection/HeroSection";
import "./home.css";
import Product from "components/Product/Products";
import Blog from "components/Blog/Blog";
import { httpClient } from "app/services/UtilServices";


const Home = () => {
  const [products, setProducts] = useState<any>([]);

  useEffect(() => {
    if (products.length === 0) {
      fetchProducts();
    }
  }, [products]);


  const fetchProducts = async () => {
    try {
      const result = await httpClient("products/all", "GET");
      if (result && result.data != null) {
        setProducts(result.data)
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };



  return (
    <div  >
      <HeroSection />
      <Blog />
      <Product products={products} />
    </div>
  );
};

export default Home


