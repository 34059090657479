import React, { useRef } from 'react';
import "./styles.css";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import BannerProduct from "../Carousel/CarouselBanner";
import { BiDownArrowAlt } from 'react-icons/bi';
import { getBanners } from 'app/services/home/GeneralAPI';

const HeroSection = () => {
  const [banners, setBanners] = React.useState([]);
  const [message, setMessage] = React.useState({});

  // React.useEffect(() => {
  //   fetchAllBanners();
  // }, []);

  // const fetchAllBanners = async () => {
  //   try {
  //     const response = await getBanners();
  //     if (response && response.data.length > 0) {
  //       const [head, ...rest] = response.data
  //       setBanners(rest)
  //       setMessage(head)
  //     }
  //   } catch (error) {
  //     console.error('Error fetching banners:', error);
  //   }
  // };



  return (
    <>
      <React.Fragment >
        <div style={{ marginTop: "10rem", justifyContent: "center" }}>
          <Grid container>
            <Grid item xs={12} md={12} sm={12}>
              <BannerProduct banners={banners} />
            </Grid>

          </Grid>
        </div>
      </React.Fragment>


    </>

  )
}

export default HeroSection;
