import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from "react-toastify";

interface CartItem {
    product: Product;
    quantity: number;
}

interface Product {
    _id: string;
    name: string;
    sellingPrice: number
}

interface CartState {
    items: CartItem[];
    totalAmount: number; // Add this field for the total amount
}

const initialState: CartState = {
    //@ts-ignore
    items: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [],
    totalAmount: 0
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addItem: (state, action: PayloadAction<{ product: Product }>) => {
            const { product } = action.payload;

            const existingItem = state.items.find(
                (item) => item.product._id === product._id
            );
            console.log("exist", existingItem)
            if (existingItem) {
                existingItem.quantity += 1;
            } else {
                state.items.push({
                    product, quantity: 1,
                });
            }

            state.totalAmount = state.items.reduce(
                (total, item) => total + item.product.sellingPrice * item.quantity,
                0
            );

            localStorage.setItem('cartItems', JSON.stringify(state.items));
        },
        updateQuantity: (
            state,
            action: PayloadAction<{ _id: string; quantity: number }>
        ) => {
            const { _id, quantity } = action.payload;

            const existingItemIndex = state.items.findIndex(
                (item) => item.product._id === _id
            );


            if (existingItemIndex !== -1) {
                if (quantity > 0) {

                    state.items[existingItemIndex].quantity = quantity;
                } else {
                    state.items.splice(existingItemIndex, 1);
                }
                if (quantity > 7) {
                    state.items[existingItemIndex].quantity = 7;
                    toast.error(`You can't add this item more than 7 quantity`)

                }

            }
            state.totalAmount = state.items.reduce(
                (total, item) => total + item.product.sellingPrice * item.quantity,
                0
            );
            localStorage.setItem('cartItems', JSON.stringify(state.items));
        },
        removeItem: (state, action: PayloadAction<{ _id: string }>) => {
            const { _id } = action.payload;
            state.items = state.items.filter(
                (item) => item.product._id !== _id
            );
            state.totalAmount = state.items.reduce(
                (total, item) => total + item.product.sellingPrice * item.quantity,
                0
            );
            localStorage.setItem('cartItems', JSON.stringify(state.items));
        },
        loadCart: (state) => {
            const storedCart = localStorage.getItem('cartItems');
            if (storedCart) {
                state.items = JSON.parse(storedCart);
            }
            state.totalAmount = state.items.reduce(
                (total, item) => total + item.product.sellingPrice * item.quantity,
                0
            );
        },
        calculateTotalAmount: (state) => {
            const totalAmount = state.items.reduce((total, item) => total + item.product.sellingPrice * item.quantity, 0);
            state.totalAmount = totalAmount;
        },
    },
});

export const { addItem, updateQuantity, removeItem, loadCart, calculateTotalAmount } = cartSlice.actions;

export default cartSlice.reducer;
