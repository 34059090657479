import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Home from "pages/HomePage";
import Login from "pages/LoginPage";
import Register from "pages/RegisterPage";
import PrivacyPolicy from "pages/PrivacyPolicyPage";
import Faq from "pages/FaqPage";
import CartPage from "pages/CartPage";
import FooterSection from "layout/Footer/Footer";
import Navbar from "layout/Navbar/Navbar";
import Profile from "pages/ProfilePage";
import ForgotPassword from "pages/ForgotPassword";
import PageNotFound from "components/Miscellaneous/PageNotFound";
import AboutUsPage from "pages/AboutUsPage";
import ProductDetails from "components/Product/ProductDetails";



const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#0ea04e',
    },
    secondary: {
      main: '#f58320',
      light: "#f58320",
    },
  },
});

const App = () => {


  return (
    <ThemeProvider theme={outerTheme}>
      <div>
        <Router>
          <Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/cart" component={CartPage} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/reset" component={ForgotPassword} />
            <Route exact path="/terms-condition" component={PrivacyPolicy} />
            <Route exact path="/Profile" component={Profile} />
            <Route exact path="/f-a-q" component={Faq} />
            <Route exact path="/aboutus" component={AboutUsPage} />
            <Route exact path="/product-details/:id" component={ProductDetails} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </Router>
        <FooterSection />
      </div>
    </ThemeProvider>
  );
};

export default App;
